<template>
  <div class="page">
    <van-nav-bar style="z-index: 10" fixed left-arrow @click-left="$router.back()" right-text="反选" @click-right="toggleAll">
      <template #title>
        <span @click="onClickTitle">{{deviceName}}</span>
      </template>
    </van-nav-bar>
    <van-tabs v-model="activeTitle" style="position: fixed;top: 46px;width: 100%;z-index: 10" @click="clickTitle">
      <van-tab v-for="(title,index) in titles" :key="title" :title="title" :name="index"></van-tab>
    </van-tabs>
    <van-pull-refresh class="refresh" v-model="refreshing" @refresh="onRefresh">
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
      >
        <van-grid :column-num="3">
          <van-grid-item v-for="cell in list" :key="cell.cellNo" @click="clickGrid(cell)">
            <div class="item">
              <span class="cell-no" :style="{color: selCell.includes(cell.cellNo) ? 'red': ''}">{{ cell.cellNo }}</span>
              <span class="order-id">{{ cell.code }}</span>
              <span class="order-id">{{ cell.orderId }}</span>
              <div class="info">
                <van-image :src="require('@/assets/door-open.png')" v-if="cell.doorOpen"></van-image>
                <van-image :src="require('@/assets/door-close.png')" v-else></van-image>
                <van-image :src="require('@/assets/light-open.png')" v-if="cell.lightOpen"></van-image>
                <van-image :src="require('@/assets/light-close.png')" v-else></van-image>
                <van-image :src="require('@/assets/uv-open.png')" v-if="cell.distinctOpen"></van-image>
                <van-image :src="require('@/assets/uv-close.png')" v-else></van-image>
                <van-image :src="require('@/assets/warm-open.png')" v-if="cell.warmOpen"></van-image>
                <van-image :src="require('@/assets/warm-close.png')" v-else></van-image>
              </div>
            </div>
          </van-grid-item>
        </van-grid>
      </van-list>
    </van-pull-refresh>

    <div style="position: fixed; right: 0; bottom: env(safe-area-inset-bottom);background-color: white;width: 100%;height: 100px;z-index: 10;overflow-x: scroll;">
      <div style="display: flex;align-items: center;gap: 10px;justify-content: flex-start;min-width: 100%;box-sizing: border-box; width: auto;padding: 0 20px" class="flex">
<!--        <div style="width: 30%;background-color: red;height: 20px"></div>-->
        <div v-if="profile.permissions.includes('control_door')" style="display: flex;flex-direction: column;align-items: center;">
          <van-button type="primary" @click="sendControl('controlDoor', true)">开门</van-button>
        </div>
        <div v-if="profile.permissions.includes('control_light')" style="display: flex;flex-direction: column;align-items: center;gap: 10px;">
          <van-button type="warning" @click="sendControl('controlLight', true)">开灯</van-button>
          <van-button type="warning" plain @click="sendControl('controlLight', false)">关灯</van-button>
        </div>
        <div v-if="profile.permissions.includes('control_warm')" style="display: flex;flex-direction: column;align-items: center;gap: 10px;">
          <van-button color="#7232dd" @click="sendControl('controlWarm', true)">开保温</van-button>
          <van-button color="#7232dd" plain @click="sendControl('controlWarm', false)">关保温</van-button>
        </div>
        <div v-if="profile.permissions.includes('control_distinct')" style="display: flex;flex-direction: column;align-items: center;gap: 10px;">
          <van-button type="info" @click="sendControl('controlDistinct', true)">开消毒</van-button>
          <van-button type="info" plain @click="sendControl('controlDistinct', false)">关消毒</van-button>
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;gap: 10px;">
          <van-button v-if="profile.permissions.includes('clean_cell')" type="danger" @click="sendControl('cleanCell', true)">清理柜格</van-button>
          <van-button v-if="profile.permissions.includes('finish_order')" type="danger" plain @click="sendControl('finishOrder', true)">完成订单</van-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      list: [],
      selCell: [],
      cabinetId: '',
      belongEleMeSystem: false,
      profile: this.$store.getters.getProfile,

      activeTitle: 0,
      titles: [],
      deviceName: '',
      delayTask: -1
    }
  },
  created() {
    this.cabinetId = this.$route.query.cabinetId
    this.belongEleMeSystem = this.$route.query.belongEleMeSystem
    this.deviceName = this.$route.query.name
  },
  destroyed() {
    if (this.delayTask !== -1) {
      clearTimeout(this.delayTask)
    }
  },
  methods: {
    onClickTitle() {
      this.$dialog({message: '确认同步格口？', showCancelButton: true}).then(() => {
        let toast = this.$toast.loading({message: '同步中，请稍候',duration: 60000})
        this.$http.get('cabinet/forceSyncCell/' + this.cabinetId).then(res => {
          toast.clear()
          if (res.code === 200) {
            this.$toast.success('同步成功')
          } else {
            this.$toast.fail(res.msg)
          }
        })
      }).catch(() => {
        console.log('cancel')
      })
    },
    toggleAll() {
      this.list.forEach(cell => {
        if (this.selCell.includes(cell.cellNo)) {
          this.selCell.splice(this.selCell.indexOf(cell.cellNo), 1)
        } else {
          this.selCell.push(cell.cellNo)
        }
      })
    },

    clickTitle() {
      this.selCell = []
      this.onLoad()
    },

    clickGrid(cell) {
      if (this.selCell.includes(cell.cellNo)) {
        this.selCell.splice(this.selCell.indexOf(cell.cellNo), 1)
      } else {
        this.selCell.push(cell.cellNo)
      }
    },
    async sendControl(action, open) {
      if (this.selCell.length === 0) {
        this.$toast.fail('请选择要操作的格口')
        return
      }
      this.$toast.loading({mask: true})
      let orderIdList = this.selCell
      if (action === 'cleanCell' || action === 'finishOrder') {
        orderIdList = []
        this.selCell.forEach(cellNo => {
          this.list.forEach(cell => {
            if (cellNo === cell.cellNo) {
              orderIdList.push(cell.orderId)
            }
          })
        })
      }


      let res = await this.$http.post('cabinet/' + action, {cabinetId: this.cabinetId, eleSystem: this.belongEleMeSystem, cellNoList: orderIdList, open: open, viceId: this.activeTitle + 1})
      this.$toast.clear()
      if (res.code === 200) {
        this.$toast.success({message:'操作成功'})
        this.delayTask = setTimeout(this.onRefresh, 2000)
      } else if (res.code === 403) {
        this.$toast.fail({message:'权限不足'})
      } else {
        this.$toast.fail(res.msg)
      }
    },

    onRefresh() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      if (!this.refreshing) this.refreshing = true
      this.$http.get('cabinet/cell', {params: {cabinetId: this.$route.query.cabinetId, viceId: this.activeTitle + 1}}).then(res => {
        this.loading = false
        this.list = res.data.cellItemList
        if (res.data.titleList) this.titles = res.data.titleList
        this.finished = true
        this.refreshing = false
        this.$bus.$emit('data-refresh', {type:'device', amount:this.list.length})
      }).catch(err => {
        this.$toast.fail(err.message)
        this.loading = false
        this.finished = true
        this.refreshing = false
      })
    },

    clickCell(cabinet) {
      console.log(cabinet)
    },
  }
}
</script>

<style scoped>
.flex>div{
  flex-shrink: 0;
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item .cell-no {
  font-size: 18px;
  font-weight: bold;
}
.item .order-id {
  font-size: 12px !important;
  color: gray;
}
.info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

/deep/ .van-list__finished-text {
  /*margin-bottom: 100px;*/
  padding-bottom: calc(env(safe-area-inset-bottom) + 150px);
  background-color: whitesmoke;
}
.refresh {
  margin-top: 92px;
  /*padding-bottom: 100px;*/
}
</style>
